@import "_cstm-variable.scss";
@import "../_cstm-mixins.scss";

.ng-select {
  &.laytrip-input-new {
    padding: 0 15px 0 0;

    .ng-dropdown-panel {
      top: 100%;
      background: $text-white;
      left: 0;
      //width: 480px;
      width: 100%;

      @media (max-width: 767px) {
        width: 410px;
      }

      @media (max-width: 480px) {
        width: 100%;
      }
    }

    .ng-input {
      input {
        padding: 0;
      }
    }

    .ng-select-container {
      display: flex !important;

      .ng-value-container {
        .ng-placeholder {
          padding: 0 15px;
        }
      }

      span {
        &.ng-arrow-wrapper {
          display: none !important;

          span {
            &.ng-arrow {
              margin: 0 !important;
              padding: 0 !important;
              font-size: 16px !important;
              color: $error-color !important;
            }
          }
        }
      }
    }

    .ng-select-opened {
      .ng-select-container {
        z-index: 0 !important;
      }
    }

    .ng-select-container {
      border: none !important;
      height: 100%;
      min-height: 0px !important;
      display: block;

      &:hover {
        box-shadow: none !important;
      }

      .ng-value-container {
        padding-left: 0px !important;

        .ng-input {
          padding: 0 15px;
          top: 0;

          input {
            padding-left: 0px !important;
          }
        }

        .ng-value {
          padding: 0 0 0 15px;
          color: $text-black;
          //overflow: unset !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          .sub-parent {
            font-size: 20px;
            color: $text-black;
            font-weight: 600;

            @include tab-view {
              font-size: 16px;
            }
          }
        }
      }

      .ng-clear-wrapper {
        padding: 0 0px 0px 5px !important;

        .ng-clear {
          font-size: 17px !important;
          color: $error-color !important;
        }
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          display: none !important;
        }
      }
    }

    .ng-dropdown-panel {
      border: none !important;
      box-shadow: none !important;

      .ng-select-bottom {
        border-top-color: $text-white !important;
      }

      .ng-dropdown-panel-items {
        max-height: 255px;

        .ng-option {
          padding: 3px 3px 3px 10px !important;
          font-size: 14px;
          font-weight: normal;
        }

        .hotel-parent {
          padding: 10px 10px 10px 10px;
          display: flex !important;

          img {
            // background: darkred;
            margin: 2px 5px 5px 0px;
          }

          .sub-parent {
            color: $text-darkblue;
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
    }
  }

  &.ng-customClass {
    .ng-select-container {
      height: 100%;
      border: none !important;
      display: flex;
      align-items: center;
      border-radius: 0;

      &:hover {
        box-shadow: none;
      }

      .ng-value-container {
        height: auto;

        .ng-value {
          height: auto;
          font-size: 14px;
          color: $text-black;
        }

        .ng-input {
          height: auto;

          input {
            height: auto !important;
            border: none !important;
          }
        }
      }
    }
  }

  &.country_code {
    .ng-select-container {
      height: 100%;
      border: none !important;
      display: flex;
      align-items: center;
      border-radius: 0;

      &:hover {
        box-shadow: none;
      }

      .ng-value-container {
        height: auto;

        .ng-value {
          height: auto;
          font-size: 14px;
          color: $text-black;
        }

        .ng-input {
          height: auto;
          padding-left: 0;

          input {
            height: auto !important;
            border: none !important;
          }
        }
      }
    }

    .ng-dropdown-panel-items {
      .ng-option {
        padding: 5px;
        font-size: 11px;
      }
    }
  }
}

.custom_range_slide .ng5-slider .ng5-slider-selection {
  background: $text_light_yellow;
}

.custom_range_slide .ng5-slider .ng5-slider-pointer {
  border: 1px solid $text_light_yellow;
  background-color: $text_light_yellow;
  width: 16px;
  height: 16px;
  top: -7px;
}

// NG-SELECT FOCUS REMOVE CSS START
.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: inset 0 1px 1px transparent, 0 0 0 3px transparent !important;
}

// NG-SELECT FOCUS REMOVE CSS END

// custom slider for hotels
.hotel_list_img_wrp {
  ngx-gallery {
    ngx-gallery-image {
      .ngx-gallery-image-wrapper {
        border-radius: 10px;

        .ngx-gallery-image {
          border-radius: 10px;
        }
      }

      .ngx-gallery-arrow-wrapper {
        &.ngx-gallery-arrow-left {
          i {
            background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/icon/left_small.svg);
            background-repeat: no-repeat;
            background-color: $text-white;
            border-radius: 50%;
            padding: 14px;
            background-position: center center;
            background-size: 7px;

            @include tab-view {
              padding: 10px;
              background-size: 5px;
              display: none;
            }

            &::before {
              content: none;
            }
          }
        }

        &.ngx-gallery-arrow-right {
          i {
            background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/icon/right_small.svg);
            background-repeat: no-repeat;
            background-color: $text-white;
            border-radius: 50%;
            padding: 14px;
            background-position: center center;
            background-size: 7px;

            @include tab-view {
              padding: 10px;
              background-size: 5px;
              display: none;
            }

            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
}

.hotel_list_img_wrp {
  .carousel {
    &.slide {
      &:focus {
        outline: none;
        border: none;
      }

      .carousel-inner {
        &:focus {
          outline: none;
          border: none;
        }

        .carousel-item {
          &:focus {
            outline: none;
            border: none;
          }
        }
      }

      .picsum-img-wrapper {
        &:focus {
          outline: none;
          border: none;
        }

        img {
          &:focus {
            outline: none;
            border: none;
          }
        }
      }

      .carousel-control-prev {
        opacity: 1;

        .carousel-control-prev-icon {
          background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/icon/left_small.svg);
          background-repeat: no-repeat;
          background-color: $text-white;
          border-radius: 50%;
          padding: 14px;
          background-position: center center;
          background-size: 7px;

          @include tab-view {
            padding: 10px;
            background-size: 5px;
            margin-left: 10px;
            // display: none;
          }
        }
      }

      .carousel-control-next {
        opacity: 1;

        .carousel-control-next-icon {
          background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/icon/right_small.svg);
          background-repeat: no-repeat;
          background-color: $text-white;
          border-radius: 50%;
          padding: 14px;
          background-position: center center;
          background-size: 7px;

          @include tab-view {
            padding: 10px;
            background-size: 5px;
            margin-right: 10px;
            // display: none;
          }
        }
      }

      .carousel-indicators {
        text-align: center;

        li {
          display: none;
          margin: 0 2px;
          width: 4px;
          height: 3px;
          border-radius: 50%;
          padding: 1px;
        }
      }
    }
  }
}

.hotel_list_img_wrp .carousel.slide .carousel-indicators li.active,
.hotel_list_img_wrp .carousel.slide .carousel-indicators li.active + li,
.hotel_list_img_wrp .carousel.slide .carousel-indicators li.active + li + li {
  display: inline-block;
}

.hotel_list_img_wrp .carousel.slide .carousel-indicators li:nth-last-child(1),
.hotel_list_img_wrp .carousel.slide .carousel-indicators li:nth-last-child(2),
.hotel_list_img_wrp .carousel.slide .carousel-indicators li:nth-last-child(3) {
  display: inline-block;
}

.hotel_list_img_wrp
  .carousel.slide
  .carousel-indicators
  li.active
  ~ li:nth-last-child(1),
.hotel_list_img_wrp
  .carousel.slide
  .carousel-indicators
  li.active
  ~ li:nth-last-child(2),
.hotel_list_img_wrp
  .carousel.slide
  .carousel-indicators
  li.active
  ~ li:nth-last-child(3) {
  display: none;
}

.hotel_list_img_wrp
  .carousel.slide
  .carousel-indicators
  li.active
  + li
  + li:nth-last-child(3),
.hotel_list_img_wrp
  .carousel.slide
  .carousel-indicators
  li.active
  + li
  + li:nth-last-child(2),
.hotel_list_img_wrp
  .carousel.slide
  .carousel-indicators
  li.active
  + li
  + li:nth-last-child(1),
.hotel_list_img_wrp
  .carousel.slide
  .carousel-indicators
  li.active
  + li:nth-last-child(3),
.hotel_list_img_wrp
  .carousel.slide
  .carousel-indicators
  li.active
  + li:nth-last-child(2),
.hotel_list_img_wrp
  .carousel.slide
  .carousel-indicators
  li.active
  + li:nth-last-child(1) {
  display: inline-block;
}

.main_slider_big {
  ngx-gallery {
    @include tab-view {
      max-height: 210px;
    }

    ngx-gallery-image {
      .ngx-gallery-image-wrapper {
        border-radius: 10px;

        .ngx-gallery-image {
          border-radius: 10px;
        }
      }

      .ngx-gallery-arrow-wrapper {
        &.ngx-gallery-arrow-left {
          i {
            background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/icon/left_small.svg);
            background-repeat: no-repeat;
            background-color: $text-white;
            border-radius: 50%;
            padding: 20px;
            background-position: center center;
            background-size: 8px;

            @include tab-view {
              padding: 10px;
              background-size: 5px;
            }

            &::before {
              content: none;
            }
          }
        }

        &.ngx-gallery-arrow-right {
          i {
            background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/icon/right_small.svg);
            background-repeat: no-repeat;
            background-color: $text-white;
            border-radius: 50%;
            padding: 20px;
            background-position: center center;
            background-size: 8px;

            @include tab-view {
              padding: 10px;
              background-size: 5px;
            }

            &::before {
              content: none;
            }
          }
        }
      }
    }
  }

  .carousel {
    &.slide {
      &:focus {
        outline: none;
        border: none;
      }

      .carousel-inner {
        &:focus {
          outline: none;
          border: none;
        }

        .carousel-item {
          &:focus {
            outline: none;
            border: none;
          }
        }
      }

      .picsum-img-wrapper {
        &:focus {
          outline: none;
          border: none;
        }

        img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: center;
          object-position: center;
          max-height: 383px;
          border-radius: 10px;

          &:focus {
            outline: none;
            border: none;
          }
        }
      }

      .carousel-control-prev {
        opacity: 1;

        .carousel-control-prev-icon {
          background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/icon/left_small.svg);
          background-repeat: no-repeat;
          background-color: $text-white;
          border-radius: 50%;
          padding: 14px;
          background-position: center center;
          background-size: 7px;

          @include tab-view {
            padding: 10px;
            background-size: 5px;
            margin-left: 10px;
            //display: none;
          }
        }
      }

      .carousel-control-next {
        opacity: 1;

        .carousel-control-next-icon {
          background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/icon/right_small.svg);
          background-repeat: no-repeat;
          background-color: $text-white;
          border-radius: 50%;
          padding: 14px;
          background-position: center center;
          background-size: 7px;

          @include tab-view {
            padding: 10px;
            background-size: 5px;
            margin-right: 10px;
            //display: none;
          }
        }
      }

      .carousel-indicators {
        text-align: center;

        li {
          display: none;
          margin: 0 2px;
          width: 4px;
          height: 3px;
          border-radius: 50%;
          padding: 1px;
        }
      }
    }
  }
}

// datepicker hotel and rentals search
ngb-datepicker {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-display: swap;

  .ngb-dp-header {
    padding: 0.75em 0;
    background-color: $bg-body;
    border-bottom: 1px solid $dark_greyc7c7;
  }

  .ngb-dp-months {
    .ngb-dp-month {
      ngb-datepicker-month-view {
        .ngb-dp-weekdays {
          background-color: $text-white !important;
          border-bottom: none !important;

          .ngb-dp-weekday {
            color: $text-black;
            font-size: 14px;
            font-style: normal;
            font-weight: bold;
          }
        }

        .ngb-dp-week {
          .ngb-dp-day {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

// hotel tab
.hotel_tab {
  .tab_upper_content {
    width: 100%;
    margin-bottom: 19px;

    .travellers {
      width: auto;
      display: inline-block;

      label {
        width: 100%;
        margin-bottom: 0px;
        font-size: 10px;
        color: $text-grey;
        font-weight: 400;
        padding: 0 15px;
        text-transform: uppercase;
      }

      .search_area {
        background: $text-white;
        position: relative;

        .travel_info {
          width: 100%;
          height: auto;
          display: flex;
          line-height: 30px;
          font-size: 14px;
          color: $dark_grey80;
          position: relative;
          border: 1px solid $dark_grey80;
          border-radius: 100px;
          cursor: pointer;
          padding: 1px 14px 1px 16px;

          span {
            font-size: 14px;
            padding: 0 3px 0 0;

            &.guest_num {
              margin-left: 6px;
            }
          }

          .drop_aerrow {
            width: 14px;
            position: relative;
            margin-left: 20px;
            background-image: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/arrow_dropdown.svg");
            background-position: right;
            background-repeat: no-repeat;
            background-size: 12px;
          }
        }

        span {
          font-size: 10px;
          font-weight: 400;
          padding: 0 20px;
        }

        .traveller_add_block {
          width: 260px;
          position: absolute;
          top: 42px;
          left: 0;
          background: $text-white;
          box-shadow: 0px 3px 15px $shadow-black;
          padding: 15px;
          border: 1px solid $grey_e_zero;
          border-radius: 10px;
          z-index: 1021;
          border: 1px solid $dark_grey80;

          .rooms_aloc {
            margin-top: 10px;

            &:first-child {
              margin-top: 0;
            }

            ul {
              margin: 0;
              padding: 0;
              list-style: none;

              li {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 0 20px;

                .left {
                  font-size: 10px;
                  font-weight: 400;
                  color: $text-grey;

                  span {
                    font-size: 16px;
                    color: $text-darkblue;
                    display: block;
                    padding: 0;
                  }
                }

                .right {
                  width: 90px;
                  display: flex;
                  align-items: center;

                  .qtyminus {
                    width: 30px;
                    height: 30px;
                    background: $text-white;
                    border: 2px solid $grey_border;
                    border-radius: 25px;
                    color: $grey_border;
                    padding: 0;
                    font-size: 18px;

                    &:hover {
                      border: 2px solid $grey_border;
                      color: $grey_border;
                    }
                  }

                  .qty {
                    width: 30px;
                    height: 30px;
                    font-size: 20px;
                    font-weight: 400;
                    color: $text-darkblue;
                    border: none;
                    text-align: center;
                    padding: 0;
                  }

                  .qtyplus {
                    width: 30px;
                    height: 30px;
                    background: $text-white;
                    border: 2px solid $grey_border;
                    border-radius: 25px;
                    color: $grey_border;
                    padding: 0;
                    font-size: 18px;

                    &:hover {
                      border: 2px solid $grey_border;
                      color: $grey_border;
                    }
                  }
                }
              }
            }
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 0 20px;

              .left {
                font-size: 10px;
                font-weight: 400;
                color: $text-grey;

                span {
                  font-size: 14px;
                  color: $text-darkblue;
                  display: block;
                  padding: 0;
                }
              }

              .right {
                width: 90px;
                display: flex;
                align-items: center;

                .qtyminus {
                  width: 30px;
                  height: 30px;
                  background: $text-white;
                  border: 2px solid $grey_border;
                  border-radius: 25px;
                  color: $grey_border;
                  padding: 0;
                  font-size: 18px;

                  &:hover {
                    border: 2px solid $grey_border;
                    color: $grey_border;
                  }
                }

                .qty {
                  width: 30px;
                  height: 30px;
                  font-size: 20px;
                  font-weight: 400;
                  color: $text-darkblue;
                  border: none;
                  text-align: center;
                }

                .qtyplus {
                  width: 30px;
                  height: 30px;
                  background: $text-white;
                  border: 2px solid $grey_border;
                  border-radius: 25px;
                  color: $grey_border;
                  padding: 0;
                  font-size: 18px;

                  &:hover {
                    border: 2px solid $grey_border;
                    color: $grey_border;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .hotels_search {
    position: relative;

    .hotels-grid {
      width: 100%;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      @include ipad-view {
        flex-wrap: wrap;
      }

      .grid_box {
        // padding: 0 5px;

        &.first {
          display: flex;
          justify-content: space-between;
          position: relative;
          width: 60%;
          // padding-left: 0;

          @include mini-screen {
            width: 56%;
          }

          @include ipad-view {
            width: 100%;
            // margin-bottom: 5px;
          }

          @include tab-view {
            flex-flow: column;
            // padding: 0;
          }

          .hotel_where {
            width: 100%;

            @include ipad-view {
              width: 100%;
            }
          }

          label {
            width: 100%;
            margin-bottom: 0px;
            font-size: 10px;
            color: $text-grey;
            font-weight: 400;
            padding: 0 15px;
          }

          .search_area {
            padding: 8px 0;
            border: 1px solid $box-border-color;
            border-radius: 8px;
            background: $text-white;

            @include tab-view {
              padding: 6px 0;
            }

            input {
              width: 100%;
              border: none;
              height: 30px;
              margin-bottom: 0px;
              font-size: 16px;
              color: $mine_black;
              font-weight: 500;

              @include ipad-view {
                font-size: 14px;
                font-weight: 500;
                padding: 0;
              }
            }

            span {
              font-size: 10px;
              font-weight: 400;
              color: $new_gray;
              padding: 0 15px;
            }
          }

          .hotel_form {
            position: relative;
            z-index: 1;

            @media (max-width: 480px) {
              width: 100%;
              z-index: auto;
            }
          }
        }

        &.secound {
          width: 30%;
          display: flex;
          justify-content: space-between;
          position: relative;

          @include mini-screen {
            width: 33%;
          }

          @include ipad-view {
            width: 100%;
            // margin-bottom: 5px;
            // padding: 0;
          }

          @include tab-view {
            // padding: 0;
          }

          label {
            width: 100%;
            margin-bottom: 0px;
            font-size: 10px;
            color: $text-grey;
            font-weight: 400;
            padding: 0 15px;
            text-transform: uppercase;
          }

          .search_area {
            padding: 8px 0;
            border: 1px solid $box-border-color;
            border-radius: 8px;
            background: $text-white;
            position: relative;

            span {
              &.p-calendar {
                width: 100%;
              }
            }

            input {
              width: 100%;
              border: none;
              height: 48px;
              margin-bottom: 1px;
              font-size: 16px;
              color: $mine_black;
              font-weight: 500;
              padding: 0 15px 0 54px;
              background: url("https://d2q1prebf1m2s9.cloudfront.net/assets/images/home-redesign/cal.svg")
                center left 15px no-repeat !important;
              cursor: pointer;

              @media (max-width: 575px) {
                .calender-responsive-view {
                  right: 0 !important;
                  left: auto !important;
                }
              }

              @include mini-screen {
                font-size: 16px;
                margin-left: 4px !important;
              }

              @include desktop {
                margin-left: 9px;
              }

              @include ipad-view {
                font-size: 14px;
                padding: 0 0 0 64px;
                font-weight: 500;
              }
            }

            .prev-next {
              width: 60%;
              display: flex;
              padding: 0 0 0 15px;
              justify-content: space-between;

              a {
                font-size: 10px;
                color: $text-grey;
              }
            }

            .date_icon {
              position: absolute;
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
            }
          }

          .date_form {
            width: 100%;
            cursor: pointer;

            p-calendar {
              display: flex;
              align-items: center;
              height: 100%;
            }

            .search_area {
              border-radius: 8px;
              height: 100%;
            }
          }
        }

        &.third {
          width: 10%;
          // padding-right: 0;

          @include ipad-view {
            width: 50%;
            margin: 0 auto;
            // padding: 0;
          }

          @media (max-width: 575px) {
            width: 100%;
          }

          .serch_btn {
            width: 100%;
            height: 100%;

            button {
              width: 100%;
              height: 100%;
              padding: 10px 25px;
              background-color: $primary;
              background-size: 27px;
              border-radius: 8px;
              border: none;
              font-size: 20px;
              color: $text-white;
              transition: 0.3s;
              position: relative;
              z-index: 9;
              overflow: hidden;
              white-space: normal;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                max-height: 29px;

                &.hover_btn {
                  display: none;
                }
              }
            }

            @media (max-width: 480px) {
              bottom: -60px;

              button {
                padding: 10px 20px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

// loader for hotel
.page_load {
  &.hotels {
    .page_laoding {
      width: 100%;

      img {
        margin: 0 0 30px;
      }

      h6 {
        font-size: 24px;
        color: $primary;
        margin: 0 0 15px;

        span {
          color: $text-darkblue;
        }
      }

      p {
        font-size: 15px;
        color: $text-grey;
        margin: 30px 0 0;
      }

      .ui-progressbar {
        position: relative;
        text-align: center;
        border-radius: 10px;
        border: none;
        height: 20px;
        background: $bg-load_grey;

        .progress-label {
          display: none;
        }
      }

      .ui-widget-header {
        background: $primary;
      }
    }
  }
}

// loader for hotel
agm-map {
  .agm-map-container-inner {
    border-radius: 10px;
  }
}

.panel_show {
  display: block !important;
}

.panel_hide {
  display: none !important;
}
