//    Font Family

$primary-font: "Poppins", sans-serif;

/*--------------------------- Color variations ----------------------*/
$primary: #007175;
$secondary: #4ab9b2;
$hotel: #ffb700;
$flight: #4ab9b2;
$sign_button: #4ab9b2;
$ext_button: #ffb700;
$comm_button: #007175;
$remove_btn: #007175;
$footer_btm: #ffb700;
$cookie_txt: #007175;
$footer: #007175;
$secondary-color: #ffb700;
$text-grey: #8188ab;
$text-black: #000000;
$text-white: #ffffff;
$text-yellow: #ffdd0f;
$text-darkblue: #012178;
$text-lightblue: #00aeef;
$background-blue: #022177;
$bg-body: #f5f5f5;
$bg-light-grey: #f3f3f7;
$tripadvisor: #00a970;
$tripadvisor_review: #007aa1;
$border-color: #efefef;
$tab-border-color: #cad1de;
$tab-bg: #f6f6fc;
$box-border-color: #d6d6d6;
$text-light-grey: #d5d7e3;
$bg-black-opacity-half: rgba(0, 0, 0, 0.5);
$bg-whitelight-gradient: rgba(255, 255, 255, 0);
$bg-halfwhite: rgba(255, 255, 255, 0.5);
$white-bg-light: rgba(255, 255, 255, 0.1);
$border-blue-light: rgba(63, 48, 249, 0.2);
$shadow-color: rgba(202, 209, 222, 0.35);
$shadow-black: rgba(0, 0, 0, 0.08);
$shadow-black-light: rgba(0, 0, 0, 0.1);
$bg-summary-one: rgba(252, 126, 102, 1);
$bg-summary-two: rgba(1, 33, 120, 1);
$bg_tab_active: #f7e7ec;
$border_tab_active: rgba(248, 38, 193, 0.35);
$text_light_yellow: #ffaa00;
$text_light_grey: #919191;
$fb-bg: #eef1f6;
$fb-bg-border: rgba(24, 84, 207, 0.15);
$social-bg: #fdefee;
$social-bg-border: rgba(24, 84, 207, 0.15);
$social-text-red: #cf2d48;
$error-color: #ff0000;
$bg-tab-detail: #ebf9fe;
$bg-light-grey: #fafafa;
$bg-title-grey: #f4f4f8;
$bg-green: #119300;
$bg-step-count: #e0e0e0;
$btn-bg: #00bffd;
$bg-layover: rgba(0, 191, 253, 0.08);
$bg-yellow: #fedd00;
$text-lightgreen: #19dd00;
$bg-load_grey: #e9e9ec;
$text_blue: #0045ff;
$text_grey_dark: #707070;
$feedback_text-clr: #d9d9d9;
$orange_light: #ffecec;
$grey_light: #f5f4fa;
$disabled-text: #323233;
$switch_active_bg: #ced8fb;
$fb-loader-bg: #f6f6f6;
$fb-loader-colorone: #f8f8f8;
$fb-loader-colortwo: #dbdbdb;
$mid_gray_bg: #878787;
$grayed_border: #bababa;
$tab-bg-gray: #e4e4e4;
$item-width: 65vw;
$transition-time: 0.7s;
$transition-text-time: calc($transition-time / 2);
$offset: 1em;
$mobile-bkp: 650px;
$api_error_text: #ff7272;
$txt_clr_blue: #0c7bff;
$new_gray: #484a55;
$deal_text: #595959;
$bg-grey-light : #D6D6D6;
// :root {
//   --z-distance: $item-width / 7.63;
//   --from-left: 1;
//   --mobile-bkp: $mobile-bkp;
// }

// ======================================= 03-08-2023
$dark_yellow: #feaa00;
$dark_yellow_ab: #ffab00;
$dark_yellow_ac: #ffac00;
$dark_yellow_aa23: #ffaa23;
$dark_yellow_aa23_opacity: #ffe7c8;
$lighter_yellow: rgb(235, 235, 228);
$lighter_yellow_hex: #fff2d8;
$light_yellow: #ffe6b3;
$light_yellowFfdd: #ffdd98;
$secondary_pack_clr: #0942ff;
$cookie_message: #004fad;
$calendar_clr: #2196f3;
$light_greyBlue: #c9d7ff;
$light_darkGrey: #e9e9e9;
$f_one_darkGrey: #f1f1f1;
$grey_e_zero: #e0e2e5;
$dark_grey: #999;
$dark_greyD3: #d3d3d3;
$triple_3: #333;
$triple_5: #555;
$dark_greyBbb: #bbb;
$dark_grey80: #808080;
$dark_greyDc: #dcd8d7;
$dark_grey5257: #52575a;
$dark_greyd0: #d0cfcf;
$dark_grey6b: #6b6b6b;
$dark_grey4c5: #4c5371;
$cart_icon: #007175;
$dark_greyD8d: #d8d8d8;
$dark_greyDc: #dcdcdc;
$dark_greyc8: #c8c8c8;
$dark_grey837: #837f7f;
$dark_grey5252: #525252;
$dark_grey5f5e: #5f5e5e;
$dark_grey5a5a: #5a5a5a;
$dark_greye6e6: #e6e6e6;
$dark_greye5e8: #e5e8eb;
$dark_greyc7c7: #c7c7c7;
$dark_greyFfaa: #ffaa0026;
$green_0b6: #ffb700;
$green_21: #21d10c;
$green_1c: #1cd304;
$green_1b: #1bd203;
$green_1cd: #1cd32e;
$darkGreen_0e9: #0e9700;
$green_1c_99: #1cd30499;
$green_1c_26: #1cd30426;
$light_green_cc: #ccf6d6;
$light_green_dc: #dcf8d8;
$black_1b: #1b181d;
$black_15: #00000015;
$black_19: rgba(0, 0, 0, 0.19);
$black_23: rgba(0, 0, 0, 0.23);
$black_28: rgba(0, 0, 0, 0.28);
$black_80: rgba(0, 0, 0, 0.8);
$black_333: #333333;
$lighter_pink: #94218f;
$dark_greenA6a: #a6a35a;
$white_60: rgba(255, 255, 255, 0.6);
$red_f30: #f30708;
$red_f63: #f63b55;
$bg-grey-light: #d6d6d6;
$perc_off: #cef372;
$grey_border: #a6a6a6;
$slick_carousel: #575d59;
$grey-light: #ccc;
$country_font: #495057;
$blue_btn: #0043fe;
$black_333: #333333;
$mine_black: #333333;
$light_grey: #dcdada;
$guest_scroll: #d6d6d688;